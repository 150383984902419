* {
    padding: 0;
    margin: 0;
    border: 0;
    box-sizing: border-box;
}

body {
    height: 100vh;
}

#root {
    height: 100%;
    background: linear-gradient(to bottom left, #01E0FF, #0000FF);
} 
  
.clickAble {
    cursor: pointer;
}

.selectedBorder {
    border: 1px solid red;
}