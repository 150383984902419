.marvel-device.note8, .marvel-device.iphone8 {
    transform: scale(0.95);
    transform-origin: top;

}
@media screen and (max-width: 1600px) {
    .marvel-device.iphone-x, .marvel-device.iphone8 {
        transform: scale(0.65);
        transform-origin: top;
    }
    .marvel-device.note8 {
        transform: scale(0.65);
        transform-origin: top;
    }
    .marvel-device.nexus5, .marvel-device.s5{
        transform: scale(0.73);
        transform-origin: top;
    }
}